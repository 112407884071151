@import ../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1024)
            background: $n2
            +dark
                background: $n6
            .number,
            .status
                background: $n1
                +dark
                    background: $n5
    &:last-child
        +t
            .col
                padding-bottom: 0
                &:after
                    display: none

.col
    position: relative
    display: table-cell
    vertical-align: top
    padding: 16px 12px
    color: $n7
    +t
        position: static
        display: flex
        align-items: center
        padding: 0
    +dark
        color: $n1
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +t
            display: none
        +dark
            background: $n6
    &:first-child
        width: 56px
        border-radius: 8px 0 0 8px
        font-size: 0
        +t
            display: none
        &:after
            left: 12px
    &:nth-child(3)
        +t
            display: none
    &:not(:first-child):not(:nth-child(2))
        padding-top: 20px
        +t
            padding-top: 0
    &:last-child
        border-radius: 0 8px 8px 0
        &:after
            right: 12px
    &:not(:first-child):not(:last-child)
        +t
            margin-bottom: 12px

.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: stretch
    +dark
        color: $n1
    &:hover
        color: $p1

.preview
    flex-shrink: 0
    width: 80px
    height: 80px
    margin-right: 20px
    border-radius: 8px
    overflow: hidden
    +t
        width: 104px
    img
        width: 100%
        height: 100%
        object-fit: cover

.product
    max-width: 170px
    margin-bottom: 4px
    +t
        max-width: 100%
        margin-bottom: 8px
        +title2

.wrap
    +t
        display: flex
        align-items: center

.category
    +caption1
    color: $n4
    +t
        font-size: 15px

.sales
    display: inline-flex
    align-items: center

.number,
.price
    display: inline-block
    padding: 0 8px
    border-radius: 6px
    background: $n3
    transition: background .2s
    +dark
        background: $n5

.price
    display: none
    +t
        display: inline-block
        margin-right: 12px

.status
    transition: background .2s

.rating
    display: flex
    align-items: center
    +base2
    svg
        margin-right: 8px
        fill: #FFC554
    span
        margin-left: 4px
        color: $n4

.ratingEmpty
    white-space: nowrap
    color: $n4
    svg
        fill: $n4

.balance
    margin-left: 8px

.box
    display: flex
    align-items: center

.line
    position: relative
    flex-shrink: 0
    width: 44px
    height: 12px
    margin-left: 8px

.progress
    position: absolute
    top: 0
    left: 0
    bottom: 0
    border-radius: 2px

.label
    display: none
    +t
        display: block
        flex-shrink: 0
        width: 124px
        padding-right: 20px
        +caption1
        color: $n4


    .modal
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgba(0, 0, 0, 0.5) // semi-transparent black background
    display: flex
    justify-content: center
    align-items: center
    z-index: 1000 // to ensure the modal is on top of other content

.modal-content
    background-color: white
    padding: 20px
    width: 80%
    max-width: 500px
    border-radius: 10px
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2) // Adding a little shadow for depth