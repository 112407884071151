::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

* {
	-moz-appearance: textfield;
}

@font-face {
	font-family: 'BaiJamjuree-Regular';
	src: url('../../public/fonts/BaiJamjuree-Regular.ttf')
	/* Add additional src entries for other formats if needed */
  }
@font-face {
	font-family: 'BaiJamjuree-Bold';
	src: url('../../public/fonts/BaiJamjuree-Bold.ttf')
	/* Add additional src entries for other formats if needed */
  }

  body{
	font-family: 'BaiJamjuree-Regular';
  }
  .baiBold{
	font-family: 'BaiJamjuree-Bold';
  }
